import React from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DvrIcon from "@mui/icons-material/Dvr";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import { useNavigate } from "react-router-dom";
const PageCard = () => {
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="page-card-box" onClick={() => navigate("/accountList")}>
          <AccountBoxIcon sx={{ fontSize: "80px" }} />
          <h3>Account</h3>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="page-card-box" onClick={() => navigate("/userList")}>
          <PersonIcon sx={{ fontSize: "80px" }} />
          <h3>Users</h3>
        </div>
      </div>
      <div
        className="col-12 col-md-6 col-lg-4 col-xl-3"
        onClick={() => navigate("/userProfileList")}
      >
        <div className="page-card-box">
          <AssignmentIndIcon sx={{ fontSize: "80px" }} />
          <h3>User Profile</h3>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div
          className="page-card-box"
          onClick={() => navigate("/facilityList")}
        >
          <StorefrontIcon sx={{ fontSize: "80px" }} />
          <h3>Facility</h3>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div
          className="page-card-box"
          onClick={() => navigate("/facilityProfileList")}
        >
          <AddLinkIcon sx={{ fontSize: "80px" }} />
          <h4>Facility Profile Map</h4>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="page-card-box" onClick={() => navigate("/nvrList")}>
          <DvrIcon sx={{ fontSize: "80px" }} />
          <h3>NVR</h3>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="page-card-box" onClick={() => navigate("/cameraList")}>
          <CameraAltIcon sx={{ fontSize: "80px" }} />
          <h3>Camera</h3>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="page-card-box" onClick={() => navigate("/deviceList")}>
          <VideogameAssetIcon sx={{ fontSize: "80px" }} />
          <h3>Device</h3>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
        <div
          className="page-card-box"
          onClick={() => navigate("/usecaseMapping")}
        >
          <VideogameAssetIcon sx={{ fontSize: "80px" }} />
          <h3>Usecase Mapping</h3>
        </div>
      </div>
    </div>
  );
};

export default PageCard;

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
import { useDispatch } from "react-redux";
import StatusCheck from "./StatusComp";
import axios from "axios";
import OperationDashEndpoints from "../../redux/features/OperationDashEndpoints";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { notifiToasterSuccess } from "../../components/common/UseToast";
import AutocompleteCompUncontrollable from "./../../components/common/AutoCompleteUncontrollable";
const nvrOptions = [
  {
    label: "Hikvision / Prama",
    value: "hikvision",
  },
  {
    label: "CP Plus / Dahua",
    value: "cpplus",
  },
  {
    label: "Uniview",
    value: "uniview",
  },
  {
    label: "Securus",
    value: "securus",
  },
];

const MainSalesForm = () => {
  const dispatch = useDispatch();
  const [ipAddress, setIpAddress] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [channelNumber, setChannelNumber] = useState("");
  const [httpPort, setHttpPort] = useState("");
  const [rtspPort, setRtspPort] = useState("");
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("");
  const [text, setText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [brand, setBrand] = useState(null);
  const [usedRtspUrl, setUsedRtspUrl] = useState("");
  useEffect(() => {
    dispatch(changeTopbarTitle("Connectivity Tester"));
  }, []);
  function handleBrand(val) {
    setBrand(val);
  }
  function validateIP(ipAddress) {
    const newErrors = {};
    setText("");
    setStatus("");
    if (!ipAddress) {
      newErrors.ipAddress = "IP Address is required";
    } else if (!/^[0-9.]+$/.test(ipAddress)) {
      newErrors.ipAddress = "Pleaase Enter Valid IP";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }
  function validateUserNamePass(username, pass) {
    const newErrors = {};
    if (!username) {
      newErrors.username = "Username is required";
    }
    if (!brand) {
      newErrors.brand = "Brand Name is required";
    }
    if (!pass) {
      newErrors.password = "Password is required";
    }
    if (!channelNumber) {
      newErrors.channelNumber = "Channel Number is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(usedRtspUrl);
    notifiToasterSuccess("Rtsp url copied successfully");
  };

  function validateHttp(http) {
    const newErrors = {};
    if (!http) {
      newErrors.httpPort = "HTTP Port is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }
  function validateRtsp(rtsp) {
    const newErrors = {};
    if (!rtsp) {
      newErrors.rtspPort = "RTSP Port is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }
  const pingIp = async () => {
    setImageUrl("");
    setUsedRtspUrl("");
    if (validateIP(ipAddress)) {
      try {
        setImageUrl("");
        setStatus("loading");
        setText("Checking IP Address");
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          OperationDashEndpoints.pingIpAddress(ipAddress),
          {
            headers,
          }
        );
        if (response.data.message == "ping successful") {
          setStatus("success");
          setText("Ip is valid");
        } else {
          setStatus("failed");
          setText("Unable To Ping IP");
        }
      } catch (error) {
        setStatus("failed");
        setText("Unable To Ping IP");
      }
    }
  };

  const telnetPort = async (type) => {
    setImageUrl("");
    setUsedRtspUrl("");
    var port = type == "http" ? httpPort : rtspPort;
    if (
      type == "http"
        ? validateHttp(httpPort) && validateIP(ipAddress)
        : validateRtsp(rtspPort) && validateIP(ipAddress)
    ) {
      try {
        setStatus("loading");
        setText(
          type == "http" ? "Checking the HTTP port" : "Checking the RTSP port"
        );
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(
          OperationDashEndpoints.checkHttpPort(ipAddress, port),
          {
            headers,
          }
        );
        if (response.data.status == "success") {
          setStatus("success");
          setText(type == "http" ? "Valid HTTP Port" : "Valid RTSP Port");
          if (type == "rtsp") {
            setTimeout(() => {
              getImageViaRtsp();
            }, 1000);
          }
        } else {
          setStatus("failed");
          setText(
            type == "http"
              ? "HTTP Port is unreachable"
              : "RTSP Port is unreachable"
          );
        }
      } catch (error) {
        setStatus("failed");
        setText(
          type == "http"
            ? "HTTP Port is unreachable"
            : "RTSP Port is unreachable"
        );
      }
    }
  };
  const getImageViaRtsp = async () => {
    setImageUrl("");
    setUsedRtspUrl("");
    if (
      validateIP(ipAddress) &&
      validateUserNamePass(username, password) &&
      validateRtsp(rtspPort)
    ) {
      try {
        setStatus("loading");
        setText("Checking the rtsp Stream");
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };
        const response2 = await axios.post(
          OperationDashEndpoints.checkAuthentication(),
          {
            host: ipAddress,
            port: httpPort,
            channel_number: channelNumber,
            username: username,
            password: password,
            brand: brand.value,
          },
          {
            headers,
          }
        );
        if (
          response2.data.message ==
          "Unauthorized!! Incorrect username or password."
        ) {
          setStatus("failed");
          setText("Authentication credentials is not correct");
          return;
        }
        let formattedData = {
          host: ipAddress,
          rtsp_port: rtspPort,
          channel_number: channelNumber,
          username: username,
          password: password,
          brand: brand.value,
        };
        const response = await axios.post(
          OperationDashEndpoints.checkRtspStream(),
          formattedData,
          {
            headers,
          }
        );
        setUsedRtspUrl(response.data.rtsp_url);
        setImageUrl(response.data.image_url);
        setStatus("success");
        setText("RTSP Stream is working");
      } catch (error) {
        console.log(error.response.data.rtsp_url);
        setUsedRtspUrl(error.response.data.rtsp_url ?? "");
        setStatus("failed");
        setText("Unable to Play RTSP Stream");
      }
    }
  };

  function handleChannelNumber(e) {
    let val = e.target.value;
    if (val < 0) return;
    setChannelNumber(val);
  }
  function handleHttpPort(e) {
    let val = e.target.value;
    if (val < 0) return;
    setHttpPort(val);
  }
  function handleRtspPort(e) {
    let val = e.target.value;
    if (val < 0) return;
    setRtspPort(val);
  }
  return (
    <div className="config-page-container">
      <form id="checkForm">
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <TextField
              fullWidth
              id="ipAddress"
              label="IP Address"
              variant="outlined"
              placeholder="Enter IP Address"
              value={ipAddress}
              onChange={(e) => setIpAddress(e.target.value)}
              error={Boolean(errors.ipAddress ?? "")}
              helperText={errors.ipAddress}
              required
            />{" "}
          </div>
          <div className="col-12 col-md-6 mb-3">
            <TextField
              fullWidth
              id="username12"
              label="Username"
              variant="outlined"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={Boolean(errors.username ?? "")}
              helperText={errors.username ?? ""}
            />{" "}
          </div>
          <div className="col-12 col-md-6 mb-3">
            <TextField
              fullWidth
              id="password12"
              label="Password"
              type="text"
              variant="outlined"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(errors.password ?? "")}
              helperText={errors.password ?? ""}
            />
          </div>{" "}
          <div className="col-12 col-md-6 mb-3">
            <TextField
              fullWidth
              id="channelNumber"
              label="Channel Number"
              variant="outlined"
              placeholder="Channel Number"
              type="number"
              value={channelNumber}
              onChange={handleChannelNumber}
              error={Boolean(errors.channelNumber ?? "")}
              helperText={errors.channelNumber ?? ""}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <TextField
              fullWidth
              id="httpPort"
              label="HTTP Port "
              variant="outlined"
              type="number"
              placeholder="HTTP Port (default 80)"
              value={httpPort}
              onChange={handleHttpPort}
              error={Boolean(errors.httpPort ?? "")}
              helperText={errors.httpPort ?? ""}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <TextField
              fullWidth
              id="rtspPort"
              label="RTSP Port"
              variant="outlined"
              type="number"
              placeholder="RTSP Port (default 554)"
              value={rtspPort}
              onChange={handleRtspPort}
              error={Boolean(errors.rtspPort ?? "")}
              helperText={errors.rtspPort ?? ""}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-6 mb-3">
            <AutocompleteCompUncontrollable
              option={nvrOptions}
              onChange={handleBrand}
              label={"NVR Brand *"}
              disableClearable={false}
              showValue={false}
            />
            <span className="error-form-fleet">{errors.brand ?? ""}</span>
          </div>
        </div>
        <StatusCheck status={status} text={text} />
        <div className="d-flex">
          <div className="mb-3">
            <Button variant="outlined" onClick={pingIp}>
              Check IP
            </Button>
          </div>
          <div className="mb-3 mx-2">
            <Button variant="outlined" onClick={() => telnetPort("http")}>
              Check HTTP
            </Button>
          </div>{" "}
          <div className="mb-3">
            <Button variant="outlined" onClick={() => telnetPort("rtsp")}>
              Check RTSP Stream
            </Button>
          </div>
        </div>
      </form>
      {usedRtspUrl && (
        <div
          style={{
            display: "flex",
            alignItems: "end",
            marginBottom: "10px",
          }}
        >
          <p
            style={{
              margin: "0",
              marginRight: "10px",
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            RTSP URL used:
            <br />
            {usedRtspUrl}
          </p>
          <IconButton onClick={copyToClipboard} aria-label="copy">
            <ContentCopyIcon />
          </IconButton>
        </div>
      )}
      {imageUrl.length > 0 && (
        <div>
          <h4>RTSP STREAM IMAGE :</h4>

          <img className="col-12" src={imageUrl} alt="rtsp-image" />
        </div>
      )}
    </div>
  );
};

export default MainSalesForm;

import React, { useState, useEffect } from "react";
import "./fleetDash.css";
import FleetDeviceTable from "./FleetDeviceTable";
import AddDeviceFromFleet from "./AddDeviceToFleet";
import AutocompleteCompUncontrollable from "../../components/common/AutoCompleteUncontrollable";
import getDataFromAPI from "../Onboarding/Utils/getDataFromAPI";
import { TextField } from "@mui/material";
import FleetDashboardAPI from "./fleetDashAPIs";
import SettingsModal from "./SettingsModal";
import { useDispatch } from "react-redux";
import { changeTopbarTitle } from "../../redux/Action/ChangeTopbarTitle";
const FleetDashMainPage = () => {
  const dispatch = useDispatch();
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelList, setChannelList] = useState([
    { label: "Agrex", value: 1 },
    { label: "VCTech", value: 2 },
    { label: "Spectra", value: 3 },
  ]);
  useEffect(() => {
    dispatch(changeTopbarTitle("Fleet Dashboard"));
  }, []);
  const [search, setSearch] = useState("");
  const accoundId = JSON.parse(localStorage.getItem("profile")).account
    .account_id;
  const isExternalVendor = accoundId == 113 ? 3 : accoundId == 25 ? 2 : -1;
  const handleSearchName = (e) => {
    setSearch(e.target.value);
  };
  const handleFacilityChange = (val) => {
    setSelectedFacility(val);
  };
  const handleChannelChnage = (val) => {
    setSelectedChannel(val);
  };
  const handleSelectedAccount = async (val) => {
    if (!val) {
      fetchFacilityData();
      setSelectedFacility(null);
    } else {
      const facilityData = await getDataFromAPI.getFacilityByAcc(val.value);
      setFacilityList(facilityData);
    }
    setSelectedAccount(val);
  };
  const fetchFacilityData = async () => {
    try {
      if (isExternalVendor != -1) return;
      const facilityData = await getDataFromAPI.getAllFacility();
      setFacilityList(facilityData);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAccountData = async () => {
    try {
      if (isExternalVendor == -1) {
        const accData = await getDataFromAPI.getAllAccount();
        setAccountList(accData);
      } else {
        const accList = await FleetDashboardAPI.getAllAccountViaChannel(
          isExternalVendor
        );
        setAccountList(accList);
      }
    } catch (error) {
      setAccountList([]);
      console.log(error);
    }
  };

  const fetchDeviceData = async () => {
    try {
      if (search.length >= 2) {
        fetchFacilityViaSearch();
        return;
      }
      var facilityData = [];
      if (selectedAccount) {
        facilityData = await FleetDashboardAPI.getFleetDevicesViaAccount(
          selectedAccount.value
        );
      }
      if (selectedFacility) {
        facilityData = await FleetDashboardAPI.getFleetDevicesViaFacility(
          selectedFacility.value
        );
      }
      if (selectedChannel) {
        facilityData = await FleetDashboardAPI.getFleetDevicesViaChannel(
          selectedChannel.value
        );
      }

      if (
        !selectedAccount &&
        !selectedFacility &&
        !selectedChannel &&
        search.length == 0
      )
        facilityData =
          isExternalVendor == -1
            ? await FleetDashboardAPI.getFleetDevices()
            : await FleetDashboardAPI.getFleetDevicesViaChannel(
                isExternalVendor
              );

      setDeviceList(facilityData);
    } catch (error) {
      setDeviceList([]);
      console.log(error);
    }
  };
  const fetchFacilityViaSearch = async () => {
    let facilityData = await FleetDashboardAPI.getFleetViaSeach(search);
    if (isExternalVendor != -1) {
      let modifiedData = facilityData.filter(
        (item) => item.channel === isExternalVendor
      );
      setDeviceList(modifiedData);
      return;
    }
    setDeviceList(facilityData);
  };
  useEffect(() => {
    fetchFacilityData();
    fetchAccountData();
  }, []);
  useEffect(() => {
    fetchDeviceData();
  }, [selectedAccount, selectedFacility, selectedChannel, search]);
  return (
    <div className="config-page-container">
      <div className="row">
        <div className="col-12 col-md-3 mb-3">
          <AutocompleteCompUncontrollable
            disableClearable={false}
            showValue={true}
            option={accountList}
            onChange={handleSelectedAccount}
            label="Select Account"
          />
        </div>
        {selectedAccount && (
          <div className="col-12 col-md-3 mb-3">
            <AutocompleteCompUncontrollable
              disableClearable={false}
              showValue={true}
              option={facilityList}
              onChange={handleFacilityChange}
              label="Select Facility"
            />
          </div>
        )}
        {isExternalVendor == -1 && (
          <div className="col-12 col-md-3 mb-3">
            <AutocompleteCompUncontrollable
              disableClearable={false}
              showValue={true}
              option={channelList}
              onChange={handleChannelChnage}
              label="Select Channel"
            />
          </div>
        )}
        <div className="form-group col-12 col-md-3">
          <TextField
            id="search"
            label="Search Device"
            variant="outlined"
            fullWidth
            type="text"
            name="Search"
            value={search}
            onChange={handleSearchName}
          />
        </div>

        <div
          className={`col-12 ${
            isExternalVendor != -1 ? "col-md" : ""
          } mt-2 mb-2 d-flex justify-content-end`}
        >
          <div>
            <SettingsModal />
          </div>
          {isExternalVendor == -1 && (
            <AddDeviceFromFleet
              isEditable={false}
              handleModal={() => {
                console.log("");
              }}
              deviceId={null}
            />
          )}
        </div>
      </div>

      <FleetDeviceTable
        deviceList={deviceList}
        isExternalVendor={isExternalVendor}
      />
    </div>
  );
};

export default FleetDashMainPage;
